@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i");
:root {
  /* Color Variables */
  --color-black-1: #040404;
  --color-black-2: #111;
  --color-black-3: #14151c;
  --color-gray-dark: #33364a;
  --color-gray: #444444;
  --color-gray-light: #999;
  --color-gray-lighter: #aaaaaa;
  --color-gray-lightest: #d4d6df;
  --color-white: #fff;
  --color-accent-1: #2a2c39;
  --color-accent-2: #0b9595;
  --color-accent-3: #0b2620;
  --color-accent-4: #009282;
  --color-accent-5: #fc8129;
  --color-accent-6: #fd9f5b;
  --color-accent-7: #fedac0;
  --color-accent-8: #ddd7d7;

  /* Font Variables */
  --font-primary-rale: "Raleway", sans-serif;
  --font-secondary-pop: "Poppins", sans-serif;
  --font-tertiary-op: "Open Sans", sans-serif;
  --font-fallback-sans: sans-serif;
}
/* * {
  outline: 1px solid red;
  margin: 0;
  padding: 0 red;
  box-sizing: border-box;
  font-family: "Noto Sans KR", sans-serif;
} */

.App {
  text-align: center;
  font-family: var(--font-tertiary-op);
  color: var(--color-gray);
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: rgba(42, 44, 57, 0.9);
}

header .navbar-brand img {
  margin: 0;
  max-height: 70px;
}

#header.header-transparent {
  background: transparent;
}

#header.header-scrolled {
  background: var(--color-accent-3);
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
.navbar {
  padding-top: 40px;
  padding-bottom: 40px;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar ul li a:hover,
.navbar ul li span:hover {
  color: var(--color-white);
}

.navbar a,
.navbar a:focus,
.navbar span,
.navbar span:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px 7px 15px;
  margin-left: 5px;
  font-size: 14px;
  color: var(--color-white);
  white-space: nowrap;
  transition: 0.3s;
  border-radius: 50px;
}

.navbar .ri-git-repository-line {
  margin-top: 12px;
  margin-bottom: 10px;
}

.navbar a:focus, 
.navbar a:active, 
.navbar span:focus, 
.navbar span:active {
  outline: none;
  box-shadow: none;
}

.navbar a i,
.navbar .ri-git-repository-line,
.navbar a:focus i,
.navbar span i,
.navbar span:focus i {
  font-size: 15px;
  line-height: 0;
}

.navbar .no-outline.active,
.navbar .no-outline:focus {
  outline: none;
  background: none;
}

.navbar a:hover,
.navbar span:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a,
.navbar li:hover > span {
  background: none;
  outline: 1px solid var(--color-accent-2);
  min-height: 30px;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 5px;
  top: calc(100% + 30px);
  margin: 5px 0 0 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: var(--color-accent-3);
  transition: 0.3s;
  border-radius: 15px;
}

.navbar .dropdown ul li {
  min-width: 200px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.navbar .dropdown ul a,
.navbar .dropdown ul span {
  color: var(--color-white);
  margin: 0 5px;
  font-size: 14px;
}

.navbar .dropdown ul a i,
.navbar .dropdown ul span i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul span:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a,
.navbar .dropdown ul li:hover > span {
  color: var(--color-white);
}

.navbar .dropdown:hover > ul {
  display: block;
  opacity: 1;
  visibility: visible;
  top: 100%;
  transition: opacity 0.3s, visibility 0.3s, top 0.3s;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  color: var(--color-white);
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(20, 21, 28, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #004d4f;
  max-height: 60vh;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus,
.navbar-mobile span,
.navbar-mobile span:focus {
  padding: 10px 20px;
  margin: 5px;
  font-size: 15px;
  color: var(--color-white);
}

.navbar-mobile a:hover,
.navbar-mobile span:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a,
.navbar-mobile li:hover > span {
  color: var(--color-white);
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: var(--color-accent-3);
  box-shadow: 0px 0px 5px var(--color-accent-4);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a,
.navbar-mobile .dropdown ul span {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i,
.navbar-mobile .dropdown ul span i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul span:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a,
.navbar-mobile .dropdown ul li:hover > span {
  color: var(--color-white);
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

.navbar .bi-chevron-down {
  height: 12px;
}

/*--------------------------------------------------------------
# Header Scrolled
--------------------------------------------------------------*/
.header-scrolled {
  background: none;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: var(--color-accent-3); /* Background color */
  color: #fff; /* Text color */
  border-radius: 50%; /* Circular shape */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-decoration: none;
  border: none; /* Remove default border */
  outline: none; /* Remove default outline */
  cursor: pointer; /* Pointer cursor on hover */
  opacity: 0; /* Start hidden */
  transform: translateY(20px); /* Start below the visible area */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Slower and smoother transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for a more polished look */
}

.back-to-top.show {
  opacity: 1; /* Fully visible */
  transform: translateY(0); /* Move to its final position */
}

.back-to-top.hidden {
  opacity: 0; /* Hidden */
  transform: translateY(
    20px
  ); /* Move down to create the effect of going down when hidden */
}

.back-to-top:hover {
  background-color: var(
    --color-accent-2
  ); /* Change background color on hover */
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  overflow: hidden;
  position: relative;
  /*background: linear-gradient(0deg, #2a2c39 0%, #33364a 100%);*/
  background: var(--color-black-1) url("https://res.cloudinary.com/dl2c0qth2/image/upload/v1730213938/Banner/banner.jpg") top right
    no-repeat;
  background-size: cover;
  padding: 0;
}

#hero2 {
  width: 100%;
  overflow: hidden;
  position: relative;
  /*background: linear-gradient(0deg, #2a2c39 0%, #33364a 100%);*/
  background: var(--color-black-1) url("https://res.cloudinary.com/dl2c0qth2/image/upload/v1730213938/Banner/banner.jpg") top right
    no-repeat;
  background-size: cover;
  padding: 0;
}
#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  position: relative;
  height: 70vh;
}
#hero h2 {
  color: var(--color-white);
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}
#hero h1 {
  color: var(--color-white);
  margin-bottom: 0;
  margin-top: 100px;
  font-size: 60px;
  font-weight: 700;
}
#hero p {
  width: 80%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: var(--color-white);
}
#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}
#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}
#hero .btn-get-started {
  font-family: var(--font-primary-rale);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  opacity: 0; /* Start invisible */
  animation: fadeIn 1.5s ease-in-out forwards; /* Fade in on load */
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: var(--color-white);
  border: 2px solid var(--color-white);
}

#hero .btn-get-started:hover {
  background: var(--color-accent-2);
  color: var(--color-white);
  text-decoration: none;
}

@keyframes fadeIn {
  to {
    opacity: 1; /* End fully visible */
  }
}

#hero .no-underline {
  text-decoration: none;
}

@media (min-width: 1024px) {
  #hero p {
    width: 60%;
  }
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}
@media (max-width: 768px) {
  #hero .carousel-container {
    height: 90vh;
  }
  #hero h2 {
    font-size: 28px;
  }
}

.hero-waves {
  display: block;
  width: 100%;
  height: 60px;
  position: relative;
  margin-top: 80px;
}

.wave1 use {
  -webkit-animation: move-forever1 10s linear infinite;
  animation: move-forever1 10s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave2 use {
  -webkit-animation: move-forever2 8s linear infinite;
  animation: move-forever2 8s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave3 use {
  -webkit-animation: move-forever3 6s linear infinite;
  animation: move-forever3 6s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@-webkit-keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@-webkit-keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

.logo-font {
  font-family: var(--font-quaternary-pac);
  color: var(--color-white);
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  margin-bottom: 0px;
}
.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--color-gray-lighter);
  font-family: var(--font-secondary-pop);
}
.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: var(--color-accent-2);
  margin: 4px 10px;
}
.section-title p {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: var(--font-secondary-pop);
  color: var(--color-accent-1);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Float Books
--------------------------------------------------------------*/
.portfolio .container {
  margin-bottom: 5px;
}

.iframeBody .floating-books {
  width: 100vw;
  max-width: 1300px;
  height: 340px;
  left: 50%;
  display: block;
  position: relative;
  overflow: visible;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (max-width: 1300px) {
  .iframeBody .floating-books {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books {
    margin-bottom: -100px;
  }
}

.iframeBody .floating-books .module.text.intro .content .buttons {
  align-items: center;
  justify-content: center;
}

.iframeBody .floating-books .content-wrapper .hint {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 14px;
  text-align: center;
  color: var(--textDiscreet);
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .hint {
    display: none;
  }
}

.iframeBody .floating-books .content-wrapper .hint .inner {
  padding-left: 10px;
}

.iframeBody .floating-books .content-wrapper .hint .inner svg {
  width: 8px;
  position: absolute;
  top: 4px;
  left: -10px;
}

.iframeBody .floating-books .content-wrapper .books .book {
  position: absolute;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  margin-right: 20px;
}

.iframeBody .floating-books .content-wrapper .books .book img {
  width: 87px;
  -webkit-filter: drop-shadow(
    0px 4px 8px rgba(0, 0, 0, 0.3)
  ); /* Reduced spread radius */
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.3));
  border-top-left-radius: 2px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 2px;
  overflow: hidden;
  float: left;
  cursor: pointer;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: all 0.2s ease;
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book img {
    width: 50px;
    margin-right: 1px;
  }
}

.iframeBody .floating-books .content-wrapper .books .book:hover img {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
  -webkit-filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.2));
}

/* Do No Harm */
.iframeBody .floating-books .content-wrapper .books .book:first-child {
  top: 25%;
  left: 6%;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Travel To FreeSoul */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(2) {
  top: 14%;
  left: 12%;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Destruction */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(3) {
  top: 5%;
  left: 19%;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(3) {
    top: 5%;
    left: -2%;
  }
}

/* Love is Timeless */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(4) {
  top: 20%;
  left: 25%;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/* Lucky Girl */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(5) {
  top: 10%;
  left: 35%;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

/* Learn From Me */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(6) {
  top: 10%;
  left: 45%;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

/* Secret River */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(7) {
  top: 10%;
  left: 52%;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(7) {
    top: 25%;
  }
}

/* Darpa Genesis */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(8) {
  top: 10%;
  left: 65%;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* The Bond */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(9) {
  top: 20%;
  left: 74%;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(9) {
    top: 24%;
    left: 72%;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}
/* Rupert */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(10) {
  top: 9%;
  left: 81%;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(10) {
    top: 2%;
    left: auto;
    right: -2%;
  }
}

/* The Wind Blows */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(11) {
  top: 38%;
  left: 78%;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
}

/* Roman The Teapot */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(12) {
  top: 10%;
  left: 89%;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(12) {
    top: 28%;
    right: 12%;
  }
}

/* Profiles */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(13) {
  top: 47%;
  left: 1%;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(13) {
    top: 40%;
  }
}

/* Emperors Regret*/
.iframeBody .floating-books .content-wrapper .books .book:nth-child(14) {
  top: 35%;
  left: 42%;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(14) {
    top: 43%;
  }
}

/* Darpa The Rise */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(15) {
  top: 30%;
  left: 60%;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(15) {
    top: 44%;
  }
}

/* Darpa The Mesial */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(16) {
  top: 41%;
  left: 68%;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(16) {
    display: none;
  }
}

/* Algernon */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(17) {
  top: 37%;
  left: 84%;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(17) {
    top: 15%;
    left: 80%;
  }
}

/* Holiday */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(18) {
  top: 27%;
  right: -2%;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(18) {
    display: none;
  }
}

/*  Bonza */
.iframeBody .floating-books .content-wrapper .books .book:nth-child(19) {
  top: 44%;
  right: 1%;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(19) {
    display: none;
  }
}

/* Remembering*/
.iframeBody .floating-books .content-wrapper .books .book:nth-child(20) {
  top: 37%;
  left: 28%;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(20) {
    top: 37%;
  }
}

/* Fibonacci*/
.iframeBody .floating-books .content-wrapper .books .book:nth-child(21) {
  top: 17%;
  left: -1%;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media screen and (max-width: 767px) {
  .iframeBody .floating-books .content-wrapper .books .book:nth-child(21) {
    display: none;
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  padding-top: 55px;
}
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}
.about .content ul {
  list-style: none;
  padding: 0;
}
.about .content ul li {
  padding-left: 28px;
  position: relative;
}
.about .content ul li + li {
  margin-top: 10px;
}
.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: var(--color-accent-2);
  line-height: 1;
}
.about .content p:last-child {
  margin-bottom: 0;
}
.about .content .btn-learn-more {
  font-family: var(--font-primary-rale);
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 5px;
  transition: 0.3s;
  line-height: 1;
  color: var(--color-accent-2);
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid var(--color-accent-2);
  background-color: transparent;
}
.about .content .btn-learn-more:hover {
  background: var(--color-accent-2);
  color: var(--color-white);
  text-decoration: none;
}

/*--------------------------------------------------------------
# About Counts
--------------------------------------------------------------*/
.counts {
  padding: 20px 0 20px;
}

.count-box .count-content {
  display: inline-block;
  text-align: left;
  max-width: 100%;
}

.counts .count-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  background: var(--color-white);
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.counts .count-box i {
  font-size: 42px;
  line-height: 0;
  margin-right: 20px;
  color: var(--color-accent-1);
  text-align: center;
}

.counts .count-box span {
  font-size: 38px;
  display: inline;
  font-weight: 800;
  color: var(--color-accent-1);
  position: relative;
  text-transform: capitalize;
  font-family: var(--font-primary-rale);
}

.counts .count-box span::after {
  content: "+";
  color: var(--color-accent-3);
  font-size: 36px;
  position: absolute;
  margin-left: 4px;
  right: -24px;
  top: 0;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: var(--font-primary-rale);
  font-size: 14px;
}

/*--------------------------------------------------------------
# Awards
--------------------------------------------------------------*/
.awards img {
  margin-bottom: 50px;
  height: 154px;
  width: 415px;
}

.awards .award-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: var(--color-white);
  box-shadow: 0 2px 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.4s ease-in-out;
  width: 100%;
  height: 100%;
}

.awards .award-box h3 {
  font-size: 18px;
  font-weight: bold;
  color: var(--color-black-2);
  margin-top: 10px;
}
.awards .award-box h4 {
  font-size: 14px;
  color: var(--color-gray-light);
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .awards img {
    height: 95px;
    width: 250px;
  }
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .nav {
  padding-top: 0px;
}
.features .nav-tabs {
  border: 0;
}
.features .nav-link {
  border: 1px solid var(--color-gray-lightest);
  padding: 15px;
  transition: 0.3s;
  color: var(--color-accent-1);
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.features .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}
.features .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.features .nav-link:hover {
  color: var(--color-accent-2);
}
.features .nav-link.active {
  border-color: var(--color-accent-2);
}

.ulnone {
  list-style: none;
  padding: 0;
}

@media (max-width: 768px) {
  .features .nav-link i {
    padding: 0;
    line-height: 1;
    font-size: 36px;
  }
}
@media (max-width: 575px) {
  .features .nav-link {
    padding: 15px;
  }
  .features .nav-link i {
    font-size: 24px;
  }
}
.features .tab-content {
  margin-top: 30px;
}
.features .tab-pane h3 {
  font-weight: 600;
  font-size: 26px;
}
.features .tab-pane ul {
  list-style: none;
  padding: 0;
}
.features .tab-pane ul li {
  padding-bottom: 10px;
}
.features .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: var(--color-accent-2);
}
.features .tab-pane p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Features Sections Modal
--------------------------------------------------------------*/
.ri-check-double-line {
  color: var(--color-accent-2);
}

.custom-modal-close .btn-close {
  color: inherit;
  opacity: 1;
}

.custom-modal-close .btn-close:hover,
.custom-modal-close .btn-close:focus {
  color: inherit;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.custom-modal-close .btn-close:focus {
  box-shadow: none;
}


/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: var(--color-white);
  box-shadow: 0 2px 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.4s ease-in-out;
  width: 100%;
  height: 100%;
}
.services .icon-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 2px 35px 0 rgba(68, 88, 144, 0.2);
}
.services .icon {
  position: absolute;
  left: -20px;
  top: calc(50% - 30px);
}
.services .icon i {
  font-size: 64px;
  line-height: 1;
  transition: 0.5s;
}
.services .title {
  margin-left: 40px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}
.services .title a {
  color: var(--color-accent-1);
  transition: ease-in-out 0.3s;
}
.services .title a:hover {
  color: var(--color-accent-2);
}
.services .description {
  font-size: 16px;
  margin-left: 40px;
  line-height: 24px;
  margin-bottom: 0;
}
.services .icon-box .bi-card-checklist,
.services .icon-box .bi-book {
  color: var(--color-accent-2);
}
.button-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--color-accent-1);
  background: none;
  border: none;
  text-align: center;
  padding: 15px;
  transition: 0.3s;
  cursor: pointer;
  font: inherit;
}
.button-link i {
  padding-right: 15px;
  font-size: 48px;
}
.button-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.button-link:hover {
  color: var(--color-accent-2);
}
.button-link.active {
  background: var(--color-accent-2);
  border-color: var(--color-accent-2);
}
/* Media Queries */
@media (max-width: 768px) {
  .button-link i {
    padding: 0;
    line-height: 1;
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .button-link {
    padding: 15px;
  }
  .button-link i {
    font-size: 24px;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 0 30px;
  margin: 30px 15px;
  text-align: center;
  min-height: 100%;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  border: 4px solid var(--color-white);
  margin: 0 auto;
}
.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: var(--color-black-2);
}
.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: var(--color-gray-light);
  padding-bottom: 30px;
}
.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: var(--color-accent-7);
  font-size: 26px;
}
.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}
.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}
.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}
.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: var(--color-white);
  opacity: 1;
  border: 1px solid var(--color-accent-2);
  outline: none; 
  box-shadow: none; 
}
.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  outline: none; 
  box-shadow: none; 
  background-color: var(--color-accent-2);
}

.video-fluid {
  width: 100%;
  min-height: 350px;
  padding: 10px;
  padding-bottom: 50px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: var(--color-accent-2);
  font-size: 18px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: var(--color-black-3);
  color: var(--color-white);
  font-size: 14px;
  text-align: center;
  padding: 30px 0;
}

#footer .footer-logo {
  max-width: 80px;
  height: auto;
  margin-bottom: 23px;
}

#footer h3 {
  font-size: 36px;
  font-weight: 700;
  color: var(--color-white);
  position: relative;
  font-family: var(--font-secondary-pop);
  padding: 0;
  margin: 0 0 15px 0;
}

#footer p {
  font-size: 15px;
  font-style: italic;
  padding: 0;
  margin: 0 0 40px 0;
}

#footer .social-links {
  margin: 0 0 40px 0;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: var(--color-accent-3);
  color: var(--color-white);
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: var(--color-accent-3);
  color: var(--color-black-3);
  text-decoration: none;
}

#footer .copyright {
  margin: 0 0 5px 0;
}

#footer .credits {
  font-size: 13px;
}

#footer .credits a {
  color: var(--color-accent-4);
  text-decoration: none;
}

#footer .credits a:hover {
  text-decoration: none;
  color: var(--color-accent-8);
}

@media (max-width: 576px) {
  #footer .footer-logo {
    max-width: 70px;
  }

  #footer h3 {
    font-size: 30px;
  }

  #footer p {
    font-size: 14px;
  }

  #footer .social-links a {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/

.contact .cont-container{
  margin-left: 5%;
  margin-right: 5%;
}

.contact .info-container {
  background-color: var(--color-primary);
  height: 100%;
  padding: 20px;
  border-radius: 10px 0 0 10px;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
}

.contact .img-fluid {
  max-width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}


.contact .info-item {
  width: 100%;
  background-color: var(--color-accent-3);
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  color: var(--color-white);
}

.contact .info-item:last-child {
  margin-bottom: 0;
}

.contact .info-item i {
  font-size: 20px;
  color: var(--color-white);
  float: left;
  width: 44px;
  height: 44px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact .info-item h4 {
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .info-item:hover i {
  background: var(--color-accent-4);
  color: var(--color-white);
}

.contact .php-email-form {
  width: 100%;
  height: 100%;
  background: var(--color-white);
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 0 10px 10px 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.email-button {
  color: var(--color-white);
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  border-radius: 3px;
  backdrop-filter: blur(10px);
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.email-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-submit {
  color: var(--color-white);
  background-color: var(--color-accent-4);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
  backdrop-filter: blur(10px);
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: var(--color-accent-4);
  color: var(--color-white);
}

.text-muted {
  color: var(--color-white);
}

#contact-us .modal-title {
  color: var(--color-white);
  font-size: 16px;
}

#contact-us .modal-body,
#contact-us .modal-body .form-label,
#contact-us .modal-body .form-check-label {
  font-size: 14px;
  color: var(--color-white);
}

#contact-us .modal-body input::placeholder,
#contact-us .modal-body textarea::placeholder {
  font-size: 14px;
}

#contact-us .modal-body input,
#contact-us .modal-body textarea {
  font-size: 14px;
  color: var(--color-white);
}

#contact-us .modal-content {
  background-color: var(--color-accent-3);
}

/*--------------------------------------------------------------
# Privacy Policy Page
--------------------------------------------------------------*/
.policy {
  padding-top: 55px;
}
.policy .content h3 {
  font-weight: 600;
  font-size: 26px;
}
.policy .content ul {
  list-style: none;
  padding: 0;
}
.policy .content ul li {
  padding-left: 28px;
  position: relative;
}
.policy .content ul li + li {
  margin-top: 10px;
}
.policy .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: var(--color-accent-2);
  line-height: 1;
}
.policy .content p:last-child {
  margin-bottom: 0;
}
.policy .content .btn-learn-more {
  font-family: var(--font-primary-rale);
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 5px;
  transition: 0.3s;
  line-height: 1;
  color: var(--color-accent-2);
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid var(--color-accent-2);
}
.policy .content .btn-learn-more:hover {
  background: var(--color-accent-2);
  color: var(--color-white);
  text-decoration: none;
}

.policy img {
  width: 130px;
  height: auto;
}

/*--------------------------------------------------------------
# Announcement Carousels
--------------------------------------------------------------*/
.announcement .content {
  padding: 1rem 0;
  margin-top: 0;
  padding-top: 0;
}

h2 {
  font-size: 20px;
}

.announcement .content .container {
  text-align: center;
}

/* Style for the hint to center its content */
.announcement .hint {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Adjust space from carousel */
}

/* Make sure inner content is centered */
.announcement .hint .inner {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Hint text style */
.announcement .hint p {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

/* Optionally, add some hover effect or transition */
.announcement .hint:hover {
  opacity: 0.8;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .announcement .hint {
    margin-top: 15px; /* Less space for smaller screens */
  }

  .announcement .hint .inner {
    flex-direction: column; /* Ensure items stack on smaller screens */
    padding: 0 10px; /* Add some padding to avoid text being too close to the edges */
  }

  .announcement .hint p {
    font-size: 12px; /* Smaller font size for mobile */
    margin-top: 5px; /* Adjust text spacing */
  }

  /* Optional: Adjust arrow size for mobile */
  .announcement .hint svg {
    width: 6px;
    height: 10px; /* Make arrow smaller on mobile */
  }
}

@media (max-width: 480px) {
  /* Further adjustments for very small screens */
  .announcement .hint p {
    font-size: 10px; /* Even smaller text on very small screens */
  }

  .announcement .hint svg {
    width: 5px;
    height: 8px; /* Further reduce arrow size */
  }
}

.announcement .img-fluid {
  max-width: 75%;
  height: auto;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  backface-visibility: hidden;
  perspective: 1000px;
  transition: transform 0.6s ease-in-out; /* Smooth transition for scaling */
}

.announcement .zoom-in {
  animation: zoomIn 3.5s ease forwards;
  outline: none;
  border: none;
}

.announcement .zoom-out {
  outline: none;
  border: none;
}

.announcement .swap-up-down {
  animation: swapUpDown 10s ease forwards;
  outline: none;
  border: none;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 2;
    transform: scale(1);
  }
}

@keyframes swapUpDown {
  0% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  40% {
    opacity: 0.7;
    transform: translateY(-100%) scale(0.9);
  }
  70% {
    opacity: 0.3;
    transform: translateY(30%) scale(0.85);
  }
  100% {
    opacity: 0;
    transform: translateY(0) scale(0.85);
  }
}




/*--------------------------------------------------------------
# Modal Advertisements
--------------------------------------------------------------*/
.advertisement .modal-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1.5s ease forwards;
  z-index: 1000;
  width: 32%; 
  height: auto;
}

.advertisement .modal-content {
  position: relative; 
  border-radius: 8px;
  display: inline-block; 
  animation: slideDown 0.5s ease forwards; 
}

.advertisement .modal-close-button {
  position: absolute;
  top: -23px;
  right: -19px; 
  background: none;
  border: none;
  font-size: 25px;
  font-weight: bold; 
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  line-height: 0; 
  color: var(--color-accent-2);
  outline: none; 
}

.advertisement .modal-close-button:focus,
.advertisement .modal-close-button:active {
  outline: none; 
  box-shadow: none;
  border: none; 
}

.advertisement .modal-gif {
  width: 100%; 
  height: 100%; 
  display: block;
  margin: 0 auto;
  border-radius: 8px;
  object-fit: cover;
}

/* .advertisement .next-arrow-gif {
  position: absolute; 
  top: 68%; 
  left: 17%; 
  transform: translateY(-50%); 
  cursor: pointer;
  width: 11%; 
  height: 11%; 
  background-color: transparent; 
  border: none; 
  padding: 0; 
  z-index: 1001;
  outline: none;
} */

.advertisement .next-arrow-gif:focus,
.advertisement .next-arrow-gif:active {
  outline: none;
  box-shadow: none;
  border: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .advertisement .modal-overlay {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%); 
    width: 90%; 
    height: auto; 
  }

  .advertisement .modal-content {
    width: 100%; 
    height: auto; 
  }

}


/*--------------------------------------------------------------
# Not Found
--------------------------------------------------------------*/
.not-found-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  text-align: center;
  background-color: #f8f9fa; 
}

.not-found.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}

.not-found h1 {
  font-size: 2.5rem;
  margin: 1rem 0;
}

.not-found p {
  font-size: 1.2rem;
  color: #777;
}

.go-back-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  text-decoration: none;
}

.go-back-icon i {
  font-size: 4rem; 
  transition: color 0.3s ease, transform 0.3s ease;
}

.go-back-icon i:hover {
  transform: scale(1.1); 
}



